var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"nav",attrs:{"id":"agentTitle"}},[_c('div',{staticClass:"navitem ",on:{"click":_vm.gotoresult}},[_vm._v("起名结果")]),_c('div',{staticClass:"navitem active"},[_vm._v("高分好名")]),_c('div',{staticClass:"navitem",on:{"click":_vm.gototeacher}},[_vm._v("人工专家起名")])]),_c('div',{staticClass:"result"},[_c('div',{staticClass:"topheader center"},[_vm._v(_vm._s(_vm.orderinfo.xname)+"宝宝起名方案")]),_c('van-divider',{style:({
         color: '#B95353',
         borderColor: '#B95353',
         padding: '0 16px',
         fontSize: '19px',
       })},[_vm._v(" 基本资料 ")]),_c('ul',{staticClass:"userinfo"},[_c('li',[_c('div',[_vm._v("姓氏："+_vm._s(_vm.orderinfo.xname))])]),_c('li',[_c('div',[_vm._v("性别："+_vm._s(_vm.orderinfo.sex))])]),_c('li',[_c('div',[_vm._v(" 出生公历（阳历）："+_vm._s(_vm.orderinfo.bDate[0])+"年"+_vm._s(_vm.orderinfo.bDate[1])+"月"+_vm._s(_vm.orderinfo.bDate[2])+"日"+_vm._s(_vm.orderinfo.bDate[3])+"时 ")])]),_c('li',[_c('div',[_vm._v(" 出生阴历（阴历）："+_vm._s(_vm.orderinfo.nongli.gzYear)+"年"+_vm._s(_vm.orderinfo.nongli.monthCn + _vm.orderinfo.nongli.dayCn)+" ")])]),(_vm.orderinfo.need_word)?_c('li',[_c('div',[_vm._v(" 起名固定字："+_vm._s(_vm.orderinfo.need_word)+"（"+_vm._s(_vm.orderinfo.needwdtype == 1 ? "中间字" : "结尾字")+"） ")])]):_vm._e()]),_c('van-divider',{style:({
         color: '#B95353',
         borderColor: '#B95353',
         padding: '16px',
         fontSize: '19px',
       })},[_vm._v(" 五行分析喜用字 ")]),_c('div',{staticClass:"tit1 ml-15"},[_vm._v(" 五行含量 ")]),_c('div',{staticClass:"center wchl"},[_c('WuxingHl',{attrs:{"score":_vm.orderinfo.askTao.qk_score,"qk_score_ratio":_vm.orderinfo.askTao.qk_score_ratio}})],1),_c('div',{staticClass:"tit1 ml-15"},[_vm._v(" 五行喜用分析 ")]),_c('div',{staticClass:"txt ml-15"},[_vm._v(" 同类含量："+_vm._s(_vm.WW[0])+" + "+_vm._s(_vm.WW[1])+"＝"+_vm._s(_vm.WWrd)+" ")]),_c('div',{staticClass:"txt ml-15"},[_vm._v(" 异类含量："+_vm._s(_vm.DW[0])+" ＋ "+_vm._s(_vm.DW[1])+" ＋"+_vm._s(_vm.DW[2])+"＝"+_vm._s(_vm.DWrd)+" ")]),_c('div',{staticClass:"txt ml-15"},[_vm._v("综合旺弱判定")]),_c('div',{staticClass:"txt ml-15"},[_vm._v(" 同类含量/异类含量＝"+_vm._s((_vm.WWrd / _vm.DWrd).toFixed(2))+" ")]),_c('div',{staticClass:"txt ml-15"},[_vm._v("经准确分析，该八字的喜用如下：")]),_c('div',{staticClass:"tit1 ml-15"},[_vm._v("喜用五行")]),_c('div',{staticClass:"txt ml-15 xjwx"},[_vm._v(" 喜用五行: "),_vm._l((_vm.orderinfo.askTao.asktao_result['喜用']),function(value,key){return _c('em',{key:key,class:_vm.enums[key]},[_vm._v(_vm._s(key))])})],2),_c('div',{staticClass:"txt ml-15 xjwx"},[_vm._v(" 忌用五行： "),_vm._l((_vm.orderinfo.askTao.asktao_result['忌用']),function(value,key){return _c('em',{key:key,class:_vm.enums[key]},[_vm._v(_vm._s(key))])})],2),_c('van-divider',{style:({
         color: '#B95353',
         borderColor: '#B95353',
         padding: '16px',
         fontSize: '19px',
       })},[_vm._v(" 好名推荐 ")]),_c('div',{staticClass:"center",attrs:{"id":"tabs"}},[_c('div',{},[_c('Namelist',{staticStyle:{"margin-top":"0"},attrs:{"data":_vm.sDnamelist},on:{"click":_vm.gotodetail}}),_c('div',{staticClass:"navicatebox"},[_c('div',{staticClass:"pre",on:{"click":_vm.prePage}},[_vm._v("上一页")]),_c('div',{staticClass:"page"},[_vm._v(_vm._s(_vm.sPage)+"/5")]),_c('div',{staticClass:"pre",on:{"click":_vm.nextPage}},[_vm._v("下一页")])])],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }