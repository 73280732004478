<template>
  <div>
    <!-- <div class="header center">{{ orderinfo.xname }}姓宝宝起名结果</div> -->
    <div class="nav" id="agentTitle">
      <div class="navitem " @click="gotoresult">起名结果</div>
      <div class="navitem active">高分好名</div>
      <div class="navitem" @click="gototeacher">人工专家起名</div>
    </div>
 
    <div class="result" >
      <div class="topheader center">{{ orderinfo.xname }}宝宝起名方案</div>

      <van-divider
        :style="{
          color: '#B95353',
          borderColor: '#B95353',
          padding: '0 16px',
          fontSize: '19px',
        }"
      >
        基本资料
      </van-divider>
      <ul class="userinfo">
        <li>
          <div>姓氏：{{ orderinfo.xname }}</div>
        </li>
        <li>
          <div>性别：{{ orderinfo.sex }}</div>
        </li>
        <li>
          <div>
            出生公历（阳历）：{{ orderinfo.bDate[0] }}年{{
              orderinfo.bDate[1]
            }}月{{ orderinfo.bDate[2] }}日{{ orderinfo.bDate[3] }}时
          </div>
        </li>
        <li>
          <div>
            出生阴历（阴历）：{{ orderinfo.nongli.gzYear }}年{{
              orderinfo.nongli.monthCn + orderinfo.nongli.dayCn
            }}
          </div>
        </li>
        <li v-if="orderinfo.need_word">
          <div>
            起名固定字：{{ orderinfo.need_word }}（{{
              orderinfo.needwdtype == 1 ? "中间字" : "结尾字"
            }}）
          </div>
        </li>
      </ul>

      <van-divider
        :style="{
          color: '#B95353',
          borderColor: '#B95353',
          padding: '16px',
          fontSize: '19px',
        }"
      >
        五行分析喜用字
      </van-divider>

      <div class="tit1 ml-15">
        五行含量
      </div>
      <div class="center wchl">
        <!-- 五行含量组件 -->
        <WuxingHl
          :score="orderinfo.askTao.qk_score"
          :qk_score_ratio="orderinfo.askTao.qk_score_ratio"
        />
      </div>
      <!-- 五行喜用分析开始 -->
      <div class="tit1 ml-15">
        五行喜用分析
      </div>
      <div class="txt ml-15">
        同类含量：{{ WW[0] }} + {{ WW[1] }}＝{{ WWrd }}
      </div>
      <div class="txt ml-15">
        异类含量：{{ DW[0] }} ＋ {{ DW[1] }} ＋{{ DW[2] }}＝{{ DWrd }}
      </div>
      <div class="txt ml-15">综合旺弱判定</div>
      <div class="txt ml-15">
        同类含量/异类含量＝{{ (WWrd / DWrd).toFixed(2) }}
      </div>
      <div class="txt ml-15">经准确分析，该八字的喜用如下：</div>
      <div class="tit1 ml-15">喜用五行</div>
      <div class="txt ml-15 xjwx">
        喜用五行:
        <em
          :class="enums[key]"
          v-for="(value, key) in orderinfo.askTao.asktao_result['喜用']"
          :key="key"
          >{{ key }}</em
        >
      </div>
      <div class="txt ml-15 xjwx">
        忌用五行：
        <em
          :class="enums[key]"
          v-for="(value, key) in orderinfo.askTao.asktao_result['忌用']"
          :key="key"
          >{{ key }}</em
        >
      </div>
      <!-- 五行喜用分析结束 -->
      <!-- 老师提醒结束 -->
      <van-divider
        :style="{
          color: '#B95353',
          borderColor: '#B95353',
          padding: '16px',
          fontSize: '19px',
        }"
      >
        好名推荐
      </van-divider>

      <div class="center" id="tabs">
        <div style="">
          <Namelist
            style="margin-top:0"
            @click="gotodetail"
            :data="sDnamelist"
          />
          <div class="navicatebox">
            <div class="pre" @click="prePage">上一页</div>
            <div class="page">{{ sPage }}/5</div>
            <div class="pre" @click="nextPage">下一页</div>
          </div>
        </div>
    
      </div>
    </div>
   
 </div>
</template>

<script>
import { Divider } from "vant";
import { Tab, Tabs } from "vant";
import { Pagination } from "vant";

import Namelist from "../components/NameListItem.vue";
import Paipan from "../components/Paipan.vue";
import WuxingCate from "../components/WuxingCate.vue";
import WuxingHl from "../components/WxHanLg.vue";

import axios from "../utils/request";
import router from "../router";

import solarLunar from "../components/datepick/solarlunar";
import { Dialog, CountDown } from "vant";
import report from "../utils/stat";

export default {
  name: "result",
  components: {
    vanDivider: Divider,
    vanTab: Tab,
    vanTabs: Tabs,
    Namelist,
    vanPagination: Pagination,
    Paipan,
    WuxingCate,
    WuxingHl,
    vanCountDown: CountDown,
  },

  computed: {
    is_weixn() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    WW() {
      return Object.keys(this.orderinfo.askTao.ww_score_ratio);
    },
    DW() {
      return Object.keys(this.orderinfo.askTao.dw_score_ratio);
    },
    WWrd() {
      return Object.keys(this.orderinfo.askTao.ww_score_ratio)
        .reduce(
          (a, b) =>
            this.orderinfo.askTao.qk_score[a] +
            this.orderinfo.askTao.qk_score[b]
        )
        .toFixed(1);
    },
    DWrd() {
      let a = 0;
      Object.keys(this.orderinfo.askTao.dw_score_ratio).forEach((key) => {
        a = a + this.orderinfo.askTao.qk_score[key];
      });
      return a.toFixed(1);
    },
    dDnamelist() {
      return this.dnames.slice((this.dPage - 1) * 10, this.dPage * 10);
    },
    sDnamelist() {
      return this.snames.slice((this.sPage - 1) * 10, this.sPage * 10);
    },
  },
  data() {
    return {
      active: "单名字",
      id: this.$route.params.id,
      dPage: 1,
      sPage: 1,
      looktype: 0,
      dnames: [],
      snames: [],
      enums: {
        木: "xys-mu",
        土: "xys-tu",
        火: "xys-huo",
        水: "xys-shui",
        金: "xys-jin",
      },
      orderinfo: {
        bDate: [2021],
        nongli: {},
        eightChara: {
          bazi: [
            {
              y: "辛丑",
            },
            {
              m: "乙未",
            },
            {
              d: "甲子",
            },
            {
              h: "甲子",
            },
          ],
          nayin: [
            {
              y: "壁上土",
            },
            {
              m: "沙中金",
            },
            {
              d: "海中金",
            },
            {
              h: "海中金",
            },
          ],
          zao: [
            {
              y: "辛丑",
            },
            {
              m: "",
            },
            {
              d: "",
            },
            {
              h: "",
            },
          ],
        },
        askTao: {
          ori_qk_score: {
            金: 5,
            木: 8,
            水: 3,
            火: 3,
            土: 9,
          },
          asktao_result: {
            喜用: {
              水: 1.5,
              金: 2.5,
            },
            忌用: {
              木: 5.6,
              火: 4.5,
              土: 18,
            },
          },
          qk_score_ratio: {
            金: "6%",
            木: "11%",
            水: "25%",
            火: "12%",
            土: "46%",
          },
          ww_score_ratio: {
            水: "38%",
            金: "62%",
          },
          dw_score_ratio: {
            木: "20%",
            火: "16%",
            土: "64%",
          },
          qk_score: {
            金: 2.5,
            木: 5.6,
            水: 1.5,
            火: 4.5,
            土: 18,
          },
        },
      },
      counttime: 10000 * 60,
    };
  },
  async beforeCreate() {
    //如果订单未支付，直接跳回首页
    try {
      let data = await axios.get("/order/" + this.$route.params.id);

      let orderinfo = data.data.data;
      // this.orderinfo = data.data.data;
      if (orderinfo.order_status != "已完成") {
        Dialog.confirm({
          message: "当前订单未支付",
          showCancelButton: false,
        }).then(() => {
          router.replace({
            name: "Index",
          }); // on confirm
        });
      }
      let birth = orderinfo.pub_birth_date
        ? orderinfo.pub_birth_date
        : orderinfo.pub_prebirth_date;
      let fdate = birth.split(" ");
      orderinfo.bDate = [...fdate[0].split("-"), ...fdate[1].split(":")];
      orderinfo.nongli = solarLunar.solar2lunar(
        orderinfo.bDate[0],
        orderinfo.bDate[1],
        orderinfo.bDate[2]
      );
      this.orderinfo = orderinfo;
    } catch (err) {
      Dialog.confirm({
        message: "获取订单详情失败",
        showCancelButton: false,
      }).then(() => {
        router.replace({
          name: "Index",
        }); // on confirm
      });
    }
  },
  async created() {
    // this.getorderinfo(this.id);
    this.fromk = this.$route.query.from;
    report("v1_plus_result");

    setTimeout(function(){
        window.scrollTo(0, 0)
    },100)
    await this.getnames();
  },
  async mounted() {

  },
  methods: {
    gototeacher() {
      router.push({
          name: "teacher",
          params: {
            id: this.$route.params.resultid,
          },
        })
        .catch((err) => {});
    },
    async paynow() {
      let orderinfo;
      if (this.is_weixn) {
        try {
          orderinfo = await axios.post("/order/confirm", {
            order_id: this.$route.params.id,
            from_type: 1,
            from_order_id: this.$route.params.id,
            paytype: 2,
          });
        } catch (err) {
          alert("下单失败");
        }
        let payinfo = await axios.get(
          `https://api.zhiyileiju.cn/api/v1/payment/2/${orderinfo.data.data.id}/wechat`
        );
        let pay = payinfo.data.data;

        wx.ready(function() {
          wx.chooseWXPay({
            timestamp: pay.timeStamp,
            nonceStr: pay.nonceStr,
            package: pay.package,
            signType: pay.signType,
            paySign: pay.paySign, // 支付签名
            success: function(res) {
              router
                .push({
                  name: "teachersuccess",
                })
                .catch((err) => {});
            },
          });
        });
      } else {
        alert("请到微信内打开此页面下单购买");
      }
    },

    gotoresult() {
      router
        .push({
          name: "payresult",
          params: {
            id: this.$route.params.resultid,
          },
        })
        .catch((err) => {});
    },

    async getnames() {
      let names = await axios.get(`/orderName/${this.id}`);
      let dname = names.data.data.name_arr["2"];
      dname.forEach((item) => {
        item.asktaoval = item.asktaoval.split("");
        item.name = item.name.split("");
        item.pinyin = item.pinyin.split(",");
      });
      this.snames = dname;
      let snmae = names.data.data.name_arr[1];
      snmae.forEach((item) => {
        item.asktaoval = item.asktaoval.split("");
        item.name = item.name.split("");
        item.pinyin = item.pinyin.split(",");
      });
      this.dnames = snmae;
    },
    prePage() {
      let looktype = this.looktype;
      if (looktype == 0) {
        if (this.sPage > 1) {
          this.sPage = this.sPage - 1;
          document.getElementById("tabs").scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
        }
      } else {
        if (this.dPage > 1) {
          this.dPage = this.dPage - 1;
          document.getElementById("tabs").scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
        }
      }
    },
    nextPage() {
      let looktype = this.looktype;
      if (looktype == 0) {
        if (this.sPage < 5) {
          this.sPage = this.sPage + 1;
          document.getElementById("tabs").scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
        }
      } else {
        if (this.dPage < 5) {
          this.dPage = this.dPage + 1;
          document.getElementById("tabs").scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
        }
      }
    },
    changetab(e) {
      this.looktype = e;
    },
    gotodetail(id) {
      let type = this.looktype == 1 ? 1 : 2;
      router
        .push({
          name: "resultdetail",
          params: {
            id: this.id,
            nameid: id,
            type,
          },
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less">
.nav {
  height: 35px;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #b95353;
  border-top: 1px solid #b95353;
  margin-bottom: 2px;

  .navitem {
    width: 50%;
    height: 100%;
    text-align: center;
    line-height: 35px;
    font-size: 14px;
    font-weight: bold;
    color: #b95353;
    background: #fff;
    border-right: 1px solid #b95353;
    &.active {
      background: #b95353;
      color: #fff;
    }
  }
}

.buy50name {
  background: url("http://cdn1.zhiyileiju.cn/WechatIMG75.png") no-repeat center;
  background-size: 100% 100%;
  background-color: #fff;
  width: calc(100% - 15px);
  margin: 10px auto;
  max-width: 640px;
  min-height: 200px;
  padding-bottom: 10px;
  .buytitle {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #b95353;
    text-align: center;
    padding-top: 18px;
  }
  .centerbox {
    margin-top: 30px;
    .cententitem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      margin-bottom: 5px;
      .btnlabel {
        color: #fff;
        font-size: 12px;
        background-color: #b95353;
        height: 20px;
        border-radius: 10px;
        line-height: 20px;
        padding: 0 10px;
      }
      .maintext {
        font-size: 15px;
        color: #b95353;
      }
      .timetip {
        font-size: 12px;
        color: #323232;
      }
      .colon {
        display: inline-block;
        margin: 0 4px;
        color: #b95353;
      }
      .block {
        display: inline-block;
        width: 15px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        background-color: #b95353;
      }
    }
  }

  .paynow {
    width: 190px;
    margin: 0 auto;
    display: block;
    margin-top: 25px;
  }
  .getnum {
    font-size: 12px;
    color: #323232;
    margin-top: 16px;
  }
}

.gotopluspage {
  width: calc(100% - 15px);
  margin: 0 auto;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  .gotboyu {
    width: 20%;
    height: 25px;
    background: #b95353;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    line-height: 25px;
    margin-right: 10px;
  }
}

.fixednav {
  position: fixed;
  top: 190px;
  right: 0;
  z-index: 1000;
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #b95353;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-top: 5px;
    line-height: 1em;
    .nvwarpe {
      border-radius: 50%;
      border: 1px solid #fff;
      flex-direction: column;
      width: 36px;
      height: 36px;
      font-size: 8px;
      color: white;
      box-sizing: border-box;
    }
  }
}

.bottomrgg {
  height: 200px;
  width: 100%;
  background: url("http://cdn1.zhiyileiju.cn/WechatIMG50.jpeg") no-repeat center;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 60px;
  .rengongbut {
    width: 80%;
    color: #a51c22 !important;
  }
}

.tehui {
  width: 90%;
  display: block;
  margin: 10px auto;
  animation: scaleDrew 2.4s ease-in-out infinite;
}

.rengongbut {
  width: 90%;
  margin: 10 auto;
  display: block;
  font-size: 18px;
  color: #fadbaa;
  font-weight: bold;
  margin-top: 20px;
  animation: scaleDrew 2.4s ease-in-out infinite;
}

.result {
  padding: 6px;
  // padding-top: 60px;
  min-height: 100vh;
  background: white;
  padding-bottom: 30px;
}

.topheader {
  font-size: 19px;
  font-weight: bold;
  color: #020202;
}

.userinfo {
  padding: 0 20px;
  padding-top: 0;
  margin-bottom: 0;

  li {
    font-size: 14px;
    line-height: 2em;
  }
}

.tit1 {
  font-size: 16px;
  font-weight: bold;
  color: #020202;
  line-height: 1.8em;
}

.ml-15 {
  margin-left: 25px;
}

.txt {
  font-size: 14px;
  font-weight: 400;
  color: #020202;
  line-height: 2em;
  margin: 6px 25px;
  margin-left: 25px;
}

.color-red {
  color: #b95353;
}

.Statistics {
  /*padding:0.2rem;*/
  padding-top: 10px;
}

.Statistics_bj {
  width: 100%;
  overflow: hidden;
}

.Statistics_P {
  width: 100%;
  font-size: 16px;
  line-height: 30px;
  text-align: left;
}

.OrdNameXys_Txt {
  width: 100%;
  line-height: 25px;
  font-size: 15px;
  text-indent: 2em;
  text-align: left;
}

.WxDongTu {
  width: 100%;
  height: 300px;
  float: left;
  margin-bottom: 15px;
}

.WxDongTu img {
  display: block;
  position: inherit;
  width: 300px;
  height: 100%;
  margin: 0 auto;
}

.wchl {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.xjwx em {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: white;
  font-size: 18px;
  line-height: 30px;
  display: inline-block;
  text-align: center;
  margin-right: 3px;
  font-style: normal;
}

.xjwx em:last-child {
  margin-right: 0;
}
</style>

<style lang="less">
.tabs {
  width: 95%;
}

.ngbtn {
  width: 110px;
  height: 35px;
  background: #b95353;
}

.navicatebox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  .pre {
    width: 110px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: #b95353;
    border-radius: 2px;
    color: white;
    margin-right: 16px;
  }

  .page {
    font-size: 14px;
  }

  .pre:last-child {
    margin-right: 0;
    margin-left: 16px;
  }
  /* //投诉 */
        .fixation_complaint{
            position: fixed;width: 25px;top: 133px;right: 0;
        }
}
</style>
